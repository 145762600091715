const ENV_VARS = {
    CLIENT_HOST_URL: import.meta.env.VITE_HOST,
    ALEXIARES_HOST_URL: import.meta.env.VITE_ALEXIARES_HOST,
    CIRCE_HOST_URL: import.meta.env.VITE_CIRCE_HOST,
    EULABEIA_HOST_URL: import.meta.env.VITE_EULABEIA_HOST,
    STRIPE_PUBLISHABLE_KEY: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
    ZOE_HOST_URL: import.meta.env.VITE_ZOE_HOST,
    USE_SENTRY: import.meta.env.VITE_USE_SENTRY,
    RUTTER_PUBLIC_KEY: import.meta.env.VITE_RUTTER_PUBLIC_KEY,
    MIXPANEL_TOKEN: import.meta.env.VITE_MIXPANEL_TOKEN,
    INTERCOM_APP_ID: import.meta.env.VITE_INTERCOM_APP_ID,
    STRIPE_CUSTOMER_PORTAL_URL: import.meta.env.VITE_STRIPE_CUSTOMER_PORTAL_URL,
    REFRESH_TOKEN_SECRET: import.meta.env.VITE_REFRESH_TOKEN_SECRET
}
Object.keys(ENV_VARS).forEach((key) => {
    if ((ENV_VARS as any)[key] === undefined) {
        throw new Error(`Missing env var ${key}`)
    }
})
export const ENV = ENV_VARS as {
    EULABEIA_HOST_URL: string
    CLIENT_HOST_URL: string
    ALEXIARES_HOST_URL: string
    CIRCE_HOST_URL: string
    STRIPE_PUBLISHABLE_KEY: string
    ZOE_HOST_URL: string
    USE_SENTRY: string
    RUTTER_PUBLIC_KEY: string
    MIXPANEL_TOKEN: string
    INTERCOM_APP_ID: string
    STRIPE_CUSTOMER_PORTAL_URL: string
    REFRESH_TOKEN_SECRET: string
}
