import { CompactEncrypt, compactDecrypt } from 'jose'
import { ENV } from 'config'
import { importKeyFromBase64 } from './secret'
import jwtDecode from 'jwt-decode'

export const encryptJWT = async (token: string) => {
    const key = await importKeyFromBase64(ENV.REFRESH_TOKEN_SECRET)
    const encoder = new TextEncoder()
    return await new CompactEncrypt(encoder.encode(JSON.stringify(token)))
        .setProtectedHeader({alg: 'dir', enc: 'A256GCM'})
        .encrypt(key)
}

export const decryptJWT = async (encryptedToken: string | null) => {
    if (!encryptedToken) {
        return null
    }

    const key = await importKeyFromBase64(ENV.REFRESH_TOKEN_SECRET)
    const {plaintext} = await compactDecrypt(encryptedToken, key)
    const decoder = new TextDecoder()
    return JSON.parse(decoder.decode(plaintext))
}

interface JwtPayload {
    exp: number
    iat: number
    sub?: string
}

export const isJWTExpired = (token: string | null): boolean => {
    if (!token) {
        return true
    }

    try {
        const decoded: JwtPayload = jwtDecode<JwtPayload>(token)
        const currentTime = Date.now() / 1000
        return decoded.exp < currentTime
    } catch (error) {
        console.error('Failed to decode JWT:', error)
        return true
    }
}

export const willJWTExpireInNextMinute = (token: string | null): boolean => {
    if (!token) {
        return true
    }

    try {
        const decoded: JwtPayload = jwtDecode<JwtPayload>(token)
        const currentTime = Date.now() / 1000
        const oneMinuteLater = currentTime + 60
        return decoded.exp < oneMinuteLater
    } catch (error) {
        console.error('Failed to decode JWT:', error);
        return true
    }
}
