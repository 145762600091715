import { generateSecret, exportJWK, importJWK } from 'jose';

const base64UrlToBase64 = (base64Url: string) => {
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    while (base64.length % 4) {
        base64 += '='
    }
    return base64
}

const base64ToBase64Url = (base64: string) =>
    base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')

export const createAndExportKey = async () => {
    const secretKey = await generateSecret('A256GCM', {extractable: true})
    const jwk = await exportJWK(secretKey)
    return base64UrlToBase64(jwk.k!)
}

export const importKeyFromBase64 = async (base64Key: string) => {
    const base64Url = base64ToBase64Url(base64Key)
    const jwk = {
        kty: 'oct',
        k: base64Url,
        alg: 'A256GCM',
        use: 'enc',
        key_ops: ['encrypt', 'decrypt'],
        ext: true,
    }
    return await importJWK(jwk, 'A256GCM')
}
