import { Conversation, ConversationContext, ConversationMessage } from "./types";

export const getPlaceholderMessages = (text: string): ConversationMessage[] => [
    {
        id: -2,
        sender: 'user',
        text,
        message_sources: [],
        conversation_id: '',
        created_at: '',
        feedback_messages: [],
        chart: null
    }, 
    {
        id: -3,
        sender: 'zoe',
        text: '',
        message_sources: [],
        conversation_id: '',
        created_at: '',
        feedback_messages: [],
        chart: null
    }
]

export const getPlaceholderConversation = (text: string): Conversation => ({
    contexts: [] as ConversationContext[],
    created_at: '',
    id: 'new',
    state: 'active',
    updated_at: '',
    user_id: '',
    messages: getPlaceholderMessages(text),
})
